import React from 'react';

import {
    IntroHolder,
    TwoColumns,
    Sidebar,
    Content,
} from '@bahaide/shared/components/InnerPage';

import InnerPage from '../../components/InnerPage';
import { OutboundLink } from 'gatsby-plugin-google-analytics';

const PressePage = () => (
    <InnerPage
        title="Informationen und Pressekontakt für Journalisten und Medienschaffende"
        description=""
    >
        <IntroHolder
            title="Presse Bereich"
            breadcrumbs={{
                'current-item':
                    'Informationen und Pressekontakt für Journalisten und Medienschaffende',
            }}
        ></IntroHolder>

        <TwoColumns>
            <Content>
                <p>
                    Diese Seite berichtet über aktuelle Entwicklungen in der
                    deutschen und weltweiten Bahá’i-Gemeinde.
                </p>
                <div>
                    <ul className="unstyled">
                        <li>
                            Informationen zur Bahá’i-Gemeinde in Deutschland:{' '}
                            <OutboundLink href="https://www.bahai.de">
                                www.bahai.de
                            </OutboundLink>
                        </li>
                        <li>
                            Informationen zur Internationalen Bahá’i Gemeinde:{' '}
                            <OutboundLink href="https://www.bahai.org">
                                www.bahai.org
                            </OutboundLink>
                        </li>
                        <li>
                            Informationen zu Lage der Bahá’i im Iran und Jemen:{' '}
                            <OutboundLink href="http://www.menschenrechte.bahai.de ">
                                www.menschenrechte.bahai.de
                            </OutboundLink>
                        </li>
                    </ul>
                </div>
            </Content>
            <Sidebar />
        </TwoColumns>
    </InnerPage>
);

export default PressePage;
